


import {
    Box,
    Flex,
    Avatar,
    useDisclosure,
    useColorModeValue,
    useColorMode,
    Circle,
    Badge,
    Image,
    Center,
    LinkBox,
    SimpleGrid,
    VStack,
    Heading,
    Text,
    HStack,
    Container,
    Stack,
    VisuallyHidden,
    chakra,
    LinkOverlay,
  } from '@chakra-ui/react'
  import { BsStar, BsStarFill, BsStarHalf } from 'react-icons/bs'
  import { FiShoppingCart } from 'react-icons/fi'
  import zesaimg from '../images/ZETDC.png'
  import oneMoney from '../images/c2.png'
  import { ReactNode} from 'react';
  import { FaInstagram, FaTwitter, FaYoutube } from 'react-icons/fa'
  import backgroundImg from '../images/backgroundImg.jpg'
  import c1 from '../images/c1.png';
  
  

  
  
  
  export default function Landing() {
 
  
    return (
      <>
        <Box bg={useColorModeValue('gray.100', 'gray.900')} px={12}
        shadow={'xl'}
        border={'1px solid'}
        bgColor={useColorModeValue('whiteAlpha.100')}
        borderColor={useColorModeValue('gray.50', 'gray.50')}
        rounded={'lg'}
        w={'100%'}
        >
          <Flex  alignItems={'center'} justifyContent={'space-between'}>
              <HStack spacing={4}>
  
            <Box>
            <Avatar
                      size={'xl'}
                      src={oneMoney}
                      />
            </Box>
            <Box
            >
              <Text fontSize={{ base: '1xl', sm: 'lg', md: 'xl'}} color='#FF7300' fontWeight={'semibold'} >OneMoney Services</Text>
            </Box> 
                      </HStack>
  
          </Flex>
        </Box>
  
 
 
      </>
    )
  }