import { Box, Center, Heading,Text, Flex, Button,Stack } from '@chakra-ui/react';
import { BsCheckCircleFill, BsFillCheckCircleFill } from 'react-icons/bs';
import {FaArrowRight, FaHome} from 'react-icons/fa';
import c2 from '../images/c2.png'
import { useNavigate } from 'react-router-dom';

export default function Success() {
  const navigate = useNavigate();
  return (
    <Box textAlign="center" py={10} px={6}>
        <Flex m={2}  justifyContent={'center'} >

<img src={c2}
      alt='logo'
      size="full"
      position="relative"
      display='block'
      width='10%'
      height='10%'
      justify={'center'}
      margin-left='100%'
      />
      </Flex>
        <Center>
        <BsFillCheckCircleFill  size={'50px'} color={'green'} />
        </Center>

        <Heading as="h2" size="xl" mt={6} mb={2}>
       Thank You
      </Heading>
      <Text as="h2" size="xl" mt={6} mb={2}>
       Airtime Purchase Success. Check your phone for sms confirmation or simply dial *134# for Balance Enquiry.
      </Text>
      <Stack justify={'center'} direction={'row'} m={6}>
          
          <Button
          rightIcon={<FaHome />} 
          w={'180px'}
          // onClick={() => navigate('10.95.1.249')}
          onClick={()=>window.location.href = "http://10.95.1.249"}
            bg={'orange.400'}
            color={'white'}
            _hover={{
              bg: 'green',
            }}>
            Return Home
          </Button>
          <Button
          rightIcon={<FaArrowRight />} 
          w={'180px'}
          // onClick={() => navigate('10.95.1.249')}
          onClick={() => navigate('/airtime-purchase')}           
           bg={'black'}
            color={'white'}
            _hover={{
              bg: 'green',
            }}>
            Buy More Airtime
          </Button>
        </Stack>
     
    </Box>
  );
}