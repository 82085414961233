import {
  Flex,
  Box,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Button,
  Heading,
  useColorModeValue,
  useToast,
  Text,
  Image,
  HStack,
  PinInput,
  PinInputField,
  Center,
  InputGroup,
  InputLeftAddon,
  useDisclosure,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
} from '@chakra-ui/react';
import React, { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import zig from '../images/Zig.jpg';
import axios from '../../api/axios';

const AIRTIMEZWL_URL = '/core/buy-airtime/zwl';

export default function BuyZWL() {
  const [receiverPhoneNumber, setReceiverPhoneNumber] = useState("");
  const [buyerPhoneNumber, setBuyerPhoneNumber] = useState("");
  const [amount, setAmount] = useState("");
  const [pin, setPin] = useState("");
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [nameError, setNameError] = useState('');
  const [pinError, setPinError] = useState("");
  const [amountError, setAmountError] = useState("");

  const navigate = useNavigate();
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = useRef();

  const handleSubmit = () => {
    setErrorMessage('');
    const res = validate();
    if (!res) {
      toast({
        title: 'Failed',
        description: "Form not complete",
        status: 'error',
        duration: 3000,
      });
      return;
    }
    setLoading(true);
    const key = "onemoney_62124e83-bff0-4dd2-8e5f-3b0339651ca0";
    axios.post(AIRTIMEZWL_URL, {
      buyerPhoneNumber: `263${buyerPhoneNumber}`,
      receiverPhoneNumber: `263${buyerPhoneNumber}`,
      amount,
      currency: "ZWL",
      pin,
    }, {
      headers: {
        "Content-type": "application/json",
        'api-key': ` ${key}`
      }
    })
    .then(response => {
      if (response.data.success) {
        navigate('/airtime-purchase-success');
        onClose();
        toast({
          title: 'Airtime Purchase Success',
          description: response.data.message,
          status: 'success',
          variant: 'left-accent',
          position: 'top',
          duration: 9000,
        });
        setLoading(false);
      } else {
        setLoading(false);
        toast({
          title: 'Airtime Purchase Failed',
          description: 'Insufficient funds to process this transaction, please fund the oneMoney account.',
          status: 'error',
          variant: 'top-accent',
          position: 'top',
          duration: 9000,
        });
      }
    })
    .catch(error => handleError(error));
  };

  const handleError = (error) => {
    if (error.response) {
      toast({
        title: "Error",
        description: `Server responded with ${error.response.status}`,
        status: "error",
        variant: 'top-accent',
        position: 'bottom',
        duration: 5000,
        isClosable: true,
      });
    } else if (error.request) {
      toast({
        title: "Network Error",
        description: "No response from server",
        status: 'info',
        variant: 'top-accent',
        position: 'top-right',
        duration: 8000,
        isClosable: true,
      });
    } else {
      toast({
        title: "Network Error",
        description: `Error: ${error.message}`,
        status: 'loading',
        variant: 'top-accent',
        position: 'top-left',
        duration: 8000,
        isClosable: true,
      });
    }
    setLoading(false);
  };

  const validate = () => {
    const PHONE_REGEX = /^(71)/;
    if (!buyerPhoneNumber) {
      setNameError('Enter Number');
      return false;
    }
    if (buyerPhoneNumber.length < 9 || buyerPhoneNumber.length > 10) {
      setNameError('Phone number invalid');
      return false;
    }
    if (!PHONE_REGEX.test(buyerPhoneNumber)) {
      setNameError('Please enter a NetOne number');
      return false;
    }
    if (!pin) {
      setPinError('Enter your pin');
      return false;
    }
    if (!amount) {
      setAmountError('Enter amount');
      return false;
    }
    return true;
  };

  return (
    <Flex direction="column" align="center" p={8} w="100%">
      <HStack spacing={8} justifyContent={'space-evenly'} mx={'auto'} px={8} w={'full'} maxW={'100%'}>
        <Box
          rounded={'lg'}
          bg={useColorModeValue('white', 'gray.700')}
          boxShadow={'lg'}
          p={8}
        >
          <Stack align={'center'} mb={4}>
            <Heading fontSize={'25px'} lineHeight={1.1} fontWeight={400} textColor={'orange.500'}>
              ZiG Airtime Purchase
            </Heading>
          </Stack>
          <Stack spacing={4}>
            <FormControl id="buyer">
              <FormLabel>Your Mobile Number:</FormLabel>
              <InputGroup>
                <InputLeftAddon children='+263' />
                <Input
                  type="text"
                  placeholder='eg.710000000'
                  onChange={(e) => setBuyerPhoneNumber(e.target.value)}
                  onFocus={() => setNameError('')}
                  borderColor={nameError ? 'red.400' : 'gray.300'}
                />
              </InputGroup>
              {nameError && <Text color={'red'}>{nameError}</Text>}
            </FormControl>
            <FormControl id="amount">
              <FormLabel>Amount:</FormLabel>
              <InputGroup>
                <InputLeftAddon children='ZiG' />
                <Input
                  type="number"
                  placeholder='0.00'
                  w={'full'}
                  onChange={(e) => setAmount(e.target.value)}
                  onFocus={() => setAmountError('')}
                  borderColor={amountError ? 'red.400' : 'gray.300'}
                />
              </InputGroup>
              {amountError && <Text color={'red'}>{amountError}</Text>}
            </FormControl>
            <Stack align={'center'} mt={4}>
              <Button
                isLoading={loading}
                loadingText='Loading'
                colorScheme='teal'
                variant='outline'
                spinnerPlacement='end'
                w={'full'}
                maxW={'250px'}
                onClick={onOpen}
                bg={'orange.400'}
                color={'white'}
                _hover={{
                  bg: 'orange.500',
                }}
              >
                Buy Now
              </Button>
            </Stack>
          </Stack>
        </Box>
        <Box w={{ base: 'full', md: '40%' }} display={{ base: 'none', md: 'block' }}>
          <Flex p={0} flex={1} justify="center" align="center">
            <Image objectFit={'contain'} height={{ base: '300px', md: '450px' }} src={zig} />
          </Flex>
        </Box>
      </HStack>
      <AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onClose}>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize='lg' fontWeight='bold'>
              Enter Pin
            </AlertDialogHeader>
            <AlertDialogBody>
              <FormControl>
                <Center>
                  <HStack>
                    <PinInput id='pin' mask onChange={setPin}>
                      <PinInputField />
                      <PinInputField />
                      <PinInputField />
                      <PinInputField />
                    </PinInput>
                  </HStack>
                </Center>
              </FormControl>
              {pinError && <Text color={'red'} mt={2}>{pinError}</Text>}
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>
                Cancel
              </Button>
              <Button colorScheme='green' onClick={handleSubmit} ml={3}>
                Proceed
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Flex>
  );
}
