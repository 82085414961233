import React from 'react';
import {
  Box,
  Flex,
  Stack,
  Heading,
  Text,
  Container,
  SimpleGrid,
  Image,
  
  Link,
  Center,
  useColorModeValue,
  Avatar,
  chakra,
  VisuallyHidden,
  Wrap,
  WrapItem,

} from '@chakra-ui/react';
import { FaInstagram, FaTwitter, FaYoutube } from 'react-icons/fa'
import { Card } from 'primereact/card';
import { Button } from 'primereact/button';
// import './CardComponent.css';

import zesaimg from '../images/ZETDC.png';
import oneMoney from '../images/c2.png';
import NavBar from './Navbar';
import land from '../images/land.jpg';
import air from '../images/airtime.jpg'

function SocialButton (props) {
  const {href, label,children} = props
  return (
    <chakra.button
      bg={useColorModeValue('blackAlpha.100', 'whiteAlpha.100')}
      rounded={'full'}
      w={8}
      h={8}
      cursor={'pointer'}
      as={'a'}
      href={href}
      display={'inline-flex'}
      alignItems={'center'}
      justifyContent={'center'}
      transition={'background 0.3s ease'}
      _hover={{
        bg: useColorModeValue('blackAlpha.200', 'whiteAlpha.200'),
      }}>
      <VisuallyHidden>{label}</VisuallyHidden>
      {children}
    </chakra.button>
  )
}

export default function Land() {
  const card = [
    {
      // id: 1,
      // title: 'ZESA Prepaid Electricity',
      // description: 'hhhhh',
      // imageURL: zesaimg,
      // link: '/buy-zesa',
      // buttonLabel: 'Buy Now', 

      id: 1,
      title: 'Zesa Prepaid Electricity',
      image: zesaimg,  // Placeholder image URL
      link: '/buy-zesa',    
      link1: '/request-token',                        // Link to buy page
      buttonLabel: 'Buy Now',  
      button2Label: 'Request Token'       ,          // Button label
    },
    // {
    //   id: 2,
    //   title: 'Request Electricity Token',
    //   image: zesaimg,
    //   link: '/request-token',
    //   buttonLabel: 'Request Token', 
    // },
    // {
    //   id: 2,
    //   title: 'Netone Airtime',
    //   imageURL: air,
    //   to: '/airtime',
    // },
  ];

  // Inline style for the orange button
  const buttonStyle = {
    backgroundColor: '#FF7300',
    border: 'none',
    color: 'white',
    cursor: 'pointer',
    padding: '0.5rem 1rem',
    borderRadius: '4px',
    fontSize: '12px',
    transition: 'background-color 0.3s ease',
  };

  const button2Style = {
    backgroundColor: '#181C14',
    border: 'none',
    color: 'white',
    cursor: 'pointer',
    padding: '0.5rem 1rem',
    borderRadius: '4px',
    fontSize: '12px',
    transition: 'background-color 0.3s ease',
  };

  // Inline style for button hover effect
  const hoverStyle = {
    backgroundColor: '#181C14', // Darker shade of orange
  };

  return (
    <>
      <NavBar />
      <Box position="relative">
        <Container
          as={SimpleGrid}
          maxW="100%"
          gridTemplateColumns={{ base: "1fr", md: "1fr 2fr" }}
          spacing={{ base: 10, lg: 32 }}
          py={{ base: 4, sm: 8, lg: 14 }}
          
        >
          
          
         
          <Stack >
            <Stack maxW="100%" mt={6} textAlign="center">
              <Heading
                fontSize={{ base: '2xl', md: '2xl', lg: '3xl' }}
                color={'#FF7300'}
              >
                OneMoney Online Payment
              </Heading>
              <Text fontSize={{ base: 'sm', sm: 'md', md: 'xl' }} color={'gray.500'}>
                Buy OneMoney products of your choice using OneMoney wallet at any given time.
              </Text>
             
              {/* <Center py={4}>
  {card.map((card)=>(

      <Box
        maxW={'320px'}
        w={'full'}
        // bg={useColorModeValue('white', 'gray.900')}
        boxShadow={'2xl'}
        rounded={'lg'}
        p={6}
        textAlign={'center'}>
        <Avatar
          size={'xl'}
          src={card.image }
          mb={4}
          pos={'relative'}
          _after={{
            content: '""',
            w: 4,
            h: 4,
            border: '2px solid white',
            rounded: 'full',
            pos: 'absolute',
            bottom: 0,
            right: 3,
          }}
        />
        <Heading fontSize={{base: 'sm', md: 'sm', lg: 'md'}} fontFamily={'body'}>
          {card.title}
        </Heading>


        <Stack mt={4} direction={'row'} spacing={2}>
          <Button
           onClick={() => window.location.href = card.link}
            flex={1}
            fontSize={'sm'}
            rounded={'full'}
            maxW={{ base: "40%", sm: "30%", md: "35%" }}
            _focus={{
              bg: '#FF7300',
            }}>
            {card.buttonLabel}
          </Button>
          <Button
            flex={1}
            fontSize={'sm'}
            rounded={'full'}
            bg={'#FF7300'}
            color={'white'}
            maxW={{ base: "50%", sm: "35%", md: "40%" }}
            boxShadow={
              '0px 1px 25px -5px rgb(66 153 225 / 48%), 0 10px 10px -5px rgb(66 153 225 / 43%)'
            }
            _hover={{
              bg: 'blue.500',
            }}
            _focus={{
              bg: '#FF7300',
            }}>
            {card.button2Label}
          </Button>
        </Stack>
      </Box>
  ))}
    </Center> */}


              <Flex wrap="wrap" justify="center" >
             
                {card.map((card) => (
                  <Box
                    key={card.id}
                    bg="white"
                     maxW={{ base: "85%", sm: "55%", md: "60%" }}
                    borderWidth="1px"
                    rounded="lg"
                    shadow="lg"
                    m={4}
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="center"
                    p={6}
                  >
                    <Heading
                      fontSize={{ base: 'sm', md: 'sm', lg: 'lg' }}
                    >
                      {card.title}
                    </Heading>
               
                    <Image
                      src={card.image}
                      alt={`Picture of ${card.title}`}
                      roundedTop="lg"
                      objectFit="contain"
                      boxSize="60%"
                    />
                    <Box
                      fontSize="lg"
                      fontWeight="semibold"
                      as="h2"
                      lineHeight="tight"
                      isTruncated
                      textAlign="center"
                      mt={4}
                    >
                     
                    </Box>
                    <Wrap direction='row' align='center' >
                      <WrapItem>

                    <Button
                      label={card.buttonLabel}
                     className="p-button-primary"
                      onClick={() => window.location.href = card.link}
                      style={buttonStyle}
                      onMouseEnter={(e) => (e.target.style.backgroundColor = hoverStyle.backgroundColor)}
                      onMouseLeave={(e) => (e.target.style.backgroundColor = buttonStyle.backgroundColor)}
                    />
                      </WrapItem>
                      <WrapItem>
              {card.button2Label &&  
                    <Button
                      label={card.button2Label}
                     className="p-button-primary"
                      onClick={() => window.location.href = '/request-token'}
                      style={button2Style}
                      onMouseEnter={(e) => (e.target.style.backgroundColor = hoverStyle.backgroundColor)}
                      onMouseLeave={(e) => (e.target.style.backgroundColor = buttonStyle.backgroundColor)}
                    />
                  }
                      </WrapItem>
                    </Wrap>
                      
                   
                  </Box>
                ))}
              </Flex>


            </Stack>
          </Stack>
          <Stack
            // bg="orange.400"
            // rounded="xl"
            // spacing={8}
            // maxW="100%"
            // align="center"
            mt="8"
            ml="8"
          >
          <Box>
              <Flex justify="center" align="center">
                <Image 
                  objectFit={'contain'} 
                  // maxW={'60%'} 
                  maxW={{ base: "60%", sm: "60%", md: "60%" }}
                  height={{ base: '60%', md: '50%' }}
                  src={land}
                  shadow="lg" />
              </Flex>
            </Box>
            </Stack>
        </Container>
      </Box>
      {/* <Box
      bg={useColorModeValue('gray.50', 'gray.900')}
      color={useColorModeValue('black', 'black')}>
      <Container
        as={Stack}
        maxW={'6xl'}
        py={4}
        spacing={4}
        justify={'center'}
        align={'center'}>
            <Avatar
                    size={'sm'}
                    src={oneMoney}
                    />
        <Stack direction={'row'} spacing={6}>
          <Box as="a" href={'#'}>
            Home
          </Box>
          <Box as="a" href={'#'}>
            About
          </Box>
          <Box as="a" href={'#'}>
            Blog
          </Box>
          <Box as="a" href={'#'}>
            Contact
          </Box>
        </Stack>
      </Container>

      <Box
        borderTopWidth={1}
        borderStyle={'solid'}
        borderColor={useColorModeValue('gray.200', 'gray.700')}>
        <Container
          as={Stack}
          maxW={'6xl'}
          py={4}
          direction={{ base: 'column', md: 'row' }}
          spacing={4}
          justify={{ base: 'center', md: 'space-between' }}
          align={{ base: 'center', md: 'center' }}>
          <Text>© 2024 OneMoney. All rights reserved</Text>
          <Stack direction={'row'} spacing={6}>
            <SocialButton label={'Twitter'} href={'#'}>
              <FaTwitter />
            </SocialButton>
            <SocialButton label={'YouTube'} href={'#'}>
              <FaYoutube />
            </SocialButton>
            <SocialButton label={'Instagram'} href={'#'}>
              <FaInstagram />
            </SocialButton>
          </Stack>
        </Container>
      </Box>
    </Box> */}
    </>
  );
}
