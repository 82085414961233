import React from 'react'
import { Tabs,
     TabList, 
     TabPanels, 
     Tab, 
     TabPanel, 
    Box,
    Breadcrumb,
    BreadcrumbItem,
    BreadcrumbLink,
    BreadcrumbSeparator,
    Flex,
    chakra,
    Image

    } from '@chakra-ui/react'
    import { BsChevronRight, BsPerson } from 'react-icons/bs';
import { NavLink, useNavigate } from 'react-router-dom'
import BuyZWL from './BuyZWL';
import BuyUSD from './BuyUSD';
import netmoney from '../images/netmoney.jpg'
import BuyZESA from './BuyZESA';
import Navbar from  '../pages/Navbar'


function AirtimeConfig() {
  return (
    <>
   <Navbar />
    <Box maxW="7xl"  mt='12'>
       
        {/* <Flex justifyContent="flex-start">
      
        <Image 
              borderRadius='full'
              boxSize='90px'
              src={netmoney}
              
              alt='logo'
              
              
              />

<chakra.h1
        marginTop={6}
        fontSize={'2xl'}
        fontWeight={'bold'}>
   Buy Airtime using OneMoney
      </chakra.h1>
            </Flex> */}
            <Flex mt={2} justifyContent="flex-end">
        <Breadcrumb fontSize={'xs'} fontWeight={'semibold'} spacing='8px' separator={<BsChevronRight color='gray.500' />}>
          <BreadcrumbItem>

          
            <NavLink to={'https://onemoney.co.zw/'}>
              <BreadcrumbLink >Home Page</BreadcrumbLink>
            </NavLink>
          </BreadcrumbItem>
          <BreadcrumbItem isCurrentPage>
            <BreadcrumbLink href='#'>Airtime Purchase</BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>
      </Flex>
<Box bg={'white'}  pt={1} px={{ base: 2, sm: 8, md: 10 }}>


<Tabs isLazy>
    <TabList  mb='1em'>
      <Tab>Buy ZWL Airtime</Tab>
        <Tab>Buy USD Airtime</Tab>
    </TabList>
    <TabPanels>
      {/* initially mounted */}
     
      <TabPanel>
       <BuyZWL/>
      </TabPanel>
      <TabPanel>
        <BuyUSD/>
      </TabPanel>
    </TabPanels>
  </Tabs>
        </Box>
  </Box>
  </>
  )
}

export default AirtimeConfig