
import React from 'react'; 
// import { TabView, TabPanel } from 'primereact/tabview';
import { FiUserPlus, FiUserX, FiUsers } from 'react-icons/fi';

import { 
    Box, 
    Tab, 
    TabList,
    TabPanels, 
    TabPanel,
    Tabs, 
    useColorModeValue,
    chakra, 
    TabIndicator,
    Breadcrumb,
    BreadcrumbItem,
    BreadcrumbLink,
    BreadcrumbSeparator,
} from '@chakra-ui/react';
import { FaGrinSquintTears, FaUserAlt, FaUsers } from 'react-icons/fa';
import { TbUserCircle, TbUserSearch, TbUsers } from 'react-icons/tb';
import BuyZESA from '../airtime/BuyZESA';
import Navbar from '../pages/Navbar'


 function ZesaConfig() {
    return (
        <>
        <Navbar />
        <Breadcrumb ml={3} mb={2} mt={4} fontWeight='medium' fontSize='sm'>
  <BreadcrumbItem>
    <BreadcrumbLink href='/admin/home.app'>Home</BreadcrumbLink>
  </BreadcrumbItem>

  <BreadcrumbItem isCurrentPage>
    <BreadcrumbLink href='/admin/user-management.app'>ZESA Prepaid Electricity</BreadcrumbLink>
  </BreadcrumbItem>
</Breadcrumb>
        <Box
        maxW="full" 
        mx={'2'}
        px={{ base: 2, md: 4 }}
        py={'5'} 
      
       >
           

<Tabs   variant='solid-rounded' colorScheme='gray' >
  <TabList mb='1em'>
    <Tab whiteSpace={'pre'} fontWeight={'bold'} >ZESA Prepaid Electricity</Tab>
    {/* <Tab whiteSpace={'pre'}><TbUserSearch size={'1.5em'} /> Employee Management</Tab> */}
  </TabList>

  <TabPanels>
    <TabPanel>
      <BuyZESA />
    </TabPanel>
    {/* <TabPanel>
      <Employees />
    </TabPanel> */}
  </TabPanels>
</Tabs>
        </Box>
        </>
    )
}
export default ZesaConfig