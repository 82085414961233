import {
  Flex,
  Box,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Button,
  Heading,
  useColorModeValue,
  useToast,
  Text,
  Image,
  HStack,
  PinInput,
  PinInputField,
  Center,
  InputGroup,
  InputLeftAddon,
  useDisclosure,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Select,
} from '@chakra-ui/react';
import React, { useRef, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import usd from '../images/usd.jpg';
import axios from '../../api/axios';

const AIRTIMEUSD_URL = '/core/buy-airtime/usd';

function BuyUSD() {
  const [receiverPhoneNumber, setReceiverPhoneNumber] = useState("");
  const [buyerPhoneNumber, setBuyerPhoneNumber] = useState("");
  const [airtimeCode, setAirtimeCode] = useState("");
  const [pin, setPin] = useState("");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = React.useRef();
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [nameError, setNameError] = useState('');
  const [receiverError, setReceiverError] = useState("");
  const [pinError, setPinError] = useState("");
  const [amountError, setAmountError] = useState("");
  const navigate = useNavigate();
  const [selectOption, setSelectOption] = useState('self');
  const [showSelf, setShowSelf] = useState(false);
  const [showOthers, setShowOthers] = useState(false);
  const toast = useToast();

  const airtimeId = [
    { label: '0.50', value: "05" },
    { label: '1.00', value: "1" },
    { label: '2.00', value: "2" },
    { label: '5.00', value: "5" },
    { label: '10.00', value: "10" },
    { label: '20.00', value: "20" },
    { label: '50.00', value: "50" },
  ];

  const options = [
    { label: 'Self', value: "self" },
    { label: 'Other', value: "other" },
  ];

  useEffect(() => {
    if (selectOption === 'self') {
      setShowSelf(true);
      setShowOthers(false);
    } else if (selectOption === 'other') {
      setShowOthers(true);
      setShowSelf(false);
    }
  }, [selectOption]);

  const handleSubmit = () => {
    setErrorMessage('');
    const isValid = validate();
    if (!isValid) {
      toast({
        title: 'Failed',
        description: "Form not complete",
        status: 'error',
        duration: 3000,
      });
      return;
    }
    setLoading(true);
    const key = "onemoney_62124e83-bff0-4dd2-8e5f-3b0339651ca0";
    axios.post(AIRTIMEUSD_URL, {
      buyerPhoneNumber: `263${buyerPhoneNumber}`,
      receiverPhoneNumber: selectOption === 'self' ? `263${buyerPhoneNumber}` : `263${receiverPhoneNumber}`,
      airtimeCode,
      pin,
    }, {
      headers: {
        "Content-type": "application/json",
        'api-key': `${key}`
      }
    })
      .then(response => {
        if (response.data.success) {
          navigate('/airtime-purchase-success');
          onClose();
          toast({
            title: 'Airtime Purchase Success',
            description: response.data.message,
            status: 'success',
            variant: 'left-accent',
            position: 'top',
            duration: 9000,
          });
          setLoading(false);
        } else {
          setLoading(false);
          toast({
            title: 'Airtime Purchase Failed',
            description: 'Insufficient funds to process this transaction, please fund the oneMoney account.',
            status: 'error',
            variant: 'top-accent',
            position: 'top',
            duration: 9000,
          });
        }
      })
      .catch(error => {
        handleError(error);
        setLoading(false);
      });
  };

  const handleError = (error) => {
    if (error.response) {
      toast({
        title: "Error",
        description: `Server responded with ${error.response.status}`,
        status: "error",
        variant: 'top-accent',
        duration: 5000,
        isClosable: true,
      });
    } else if (error.request) {
      toast({
        title: "Network Error",
        description: "No response from server",
        status: 'info',
        variant: 'top-accent',
        position: 'top-right',
        duration: 8000,
        isClosable: true,
      });
    } else {
      toast({
        title: "Network Error",
        description: `Error: ${error.message}`,
        status: 'loading',
        variant: 'subtle',
        position: 'top-left',
        duration: 8000,
        isClosable: true,
      });
    }
  };

  const validate = () => {
    const PHONE_REGEX = /^(71)/;
    if (!buyerPhoneNumber) {
      setNameError('Enter Number');
      return false;
    }
    if (buyerPhoneNumber.length < 9 || buyerPhoneNumber.length > 10) {
      setNameError('Phone number invalid');
      return false;
    }
    if (!PHONE_REGEX.test(buyerPhoneNumber)) {
      setNameError('Please enter a NetOne number');
      return false;
    }
    if (showOthers && (!receiverPhoneNumber || !PHONE_REGEX.test(receiverPhoneNumber) || receiverPhoneNumber.length < 9 || receiverPhoneNumber.length > 10)) {
      setReceiverError('Please enter a valid NetOne number for the receiver');
      return false;
    }
    if (!pin) {
      setPinError('Enter your pin');
      return false;
    }
    if (!airtimeCode) {
      setAmountError('Enter amount');
      return false;
    }
    return true;
  };

  return (
    <Flex direction="column" align="center" p={8} w="full">
      <HStack spacing={8} justifyContent={'space-evenly'} mx={'auto'} px={8} w={'full'} maxW={'1000px'}>
        <Box
          rounded={'lg'}
          bg={useColorModeValue('white', 'gray.700')}
          boxShadow={'lg'}
          p={8}
        >
          <Stack align={'center'} mb={4}>
            <Heading fontSize={'25px'} lineHeight={1.1} fontWeight={400} textColor={'orange.500'}>
              USD$ Airtime Purchase
            </Heading>
          </Stack>
          <FormControl mb={4}>
            <FormLabel>Buy Airtime for:</FormLabel>
            <Select placeholder='Select option' defaultValue={'self'} w={'150px'} onChange={(e) => setSelectOption(e.target.value)}>
              {options.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </Select>
          </FormControl>
          <FormControl id="buyer" mb={4}>
            <FormLabel>Your Mobile Number:</FormLabel>
            <InputGroup>
              <InputLeftAddon children='+263' />
              <Input type="text" placeholder='eg.710000000' onChange={(e) => setBuyerPhoneNumber(e.target.value)}
                onFocus={() => { setNameError('') }} borderColor={nameError ? 'red.400' : 'gray.300'}
              />
            </InputGroup>
            <Text color={'red'}>{nameError}</Text>
          </FormControl>
          {showOthers &&
            <FormControl id="receiver" mb={4}>
              <FormLabel>Receiver's Mobile Number:</FormLabel>
              <InputGroup>
                <InputLeftAddon children='+263' />
                <Input type="text" placeholder='71000000' onChange={(e) => setReceiverPhoneNumber(e.target.value)}
                  onFocus={() => { setReceiverError('') }} borderColor={receiverError ? 'red.400' : 'gray.300'}
                />
              </InputGroup>
              <Text color={'red'}>{receiverError}</Text>
            </FormControl>
          }
          <FormControl id="amount" mb={4}>
            <FormLabel>Amount:</FormLabel>
            <InputGroup>
              <InputLeftAddon children='USD$' />
              <Select placeholder='Select amount:' w={'220px'} bg={'white'} onChange={(e) => setAirtimeCode(e.target.value)}
                onFocus={() => { setAmountError('') }} borderColor={amountError ? 'red.400' : 'gray.300'}
              >
                {airtimeId.map((opt) => (
                  <option key={opt.value} value={opt.value}>
                    {opt.label}
                  </option>
                ))}
              </Select>
            </InputGroup>
            <Text color={'red'}>{amountError}</Text>
          </FormControl>
          <Stack align={'center'} mt={4}>
            <Button
              isLoading={loading}
              loadingText='Loading'
              colorScheme='orange'
              onClick={onOpen}
              bg={'orange.400'}
              color={'white'}
              _hover={{ bg: 'orange.500' }}
            >
              Buy Now
            </Button>
          </Stack>
        </Box>
        <Box w={{ base: 'full', md: '40%' }} display={{ base: 'none', md: 'block' }}>
          <Flex justify="center" align="center">
            <Image objectFit={'contain'} height={{ base: '300px', md: '450px' }} src={usd} />
          </Flex>
        </Box>
      </HStack>
      <AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onClose}>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize='lg' fontWeight='bold'>
              Enter Pin
            </AlertDialogHeader>
            <AlertDialogBody>
              <FormControl>
                <Center>
                  <HStack>
                    <PinInput id='pin' mask onChange={x => setPin(x)}>
                      <PinInputField />
                      <PinInputField />
                      <PinInputField />
                      <PinInputField />
                    </PinInput>
                  </HStack>
                </Center>
              </FormControl>
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>
                Cancel
              </Button>
              <Button colorScheme='green' onClick={handleSubmit} ml={3}>
                Proceed
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Flex>
  );
}

export default BuyUSD;
