// App.js
import React, { useState } from 'react';
import {
  Box,
  Button,
  Center,
  FormControl,
  FormLabel,
  Input,
  Spinner,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Alert,
  AlertIcon,
  Breadcrumb,
    BreadcrumbItem,
    BreadcrumbLink,
    Image,
    Flex,
    Stack,
    VStack,
    Heading,
    useToast,
    Divider,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Text,
    useDisclosure,
    HStack,
    AbsoluteCenter,
} from '@chakra-ui/react';
import 'primereact/resources/themes/saga-blue/theme.css';  
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import axios from 'axios';
import Navbar from '../pages/Navbar';
import zesa from '../images/zesa.jpg';

const REQUESTTOKEN_URL = 'https://onboarding.onemoney.co.zw/mobile-api/tokens/'

const RequestToken = () => {
  const [meterNumber, setMeterNumber] = useState('');
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const toast = useToast()

  const { isOpen: isConOpen, onOpen: onConOpen, onClose: onConClose } = useDisclosure()


  const handleFetchData = async () => {
    setLoading(true);
    setError('');
    try {
      // Replace this URL with your actual API endpoint
      const response = await axios.get(`https://shop.onemoney.co.zw/api/zesa/tokens/${meterNumber}`);
      // setData(response.data); // Adjust this based on your API's response structure
      if(response.data.success === true) {
        setData(response.data.data);
        console.log(response.data);
        setLoading(false)
      }else {
        toast({
          title: "Error",
          description: `${response.data.message}`,
          status: "error",
          variant:'left-accent',
          position:'top',
          duration: 5000,
          isClosable: true,
        });
      }
    
    } catch (err) {
      
      setError('Error fetching data. Please try again.');
      console.error('Error fetching data:', err);
    } finally {
      setLoading(false);
      
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!meterNumber) {
      setError('Please enter a meter number.');
      return;
    }
    handleFetchData();
  };

  return (
    <>
     <Navbar />
        <Breadcrumb m={6} fontWeight='medium' fontSize='sm'>
        <BreadcrumbItem>
          <BreadcrumbLink href='/'>Home</BreadcrumbLink>
        </BreadcrumbItem>

        <BreadcrumbItem isCurrentPage>
          <BreadcrumbLink href='/admin/user-management.app'>Request Zesa Electricity Tokens</BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>
      <Box>

    <Stack marginLeft={{ base: '0', sm: '4', md: '8'}} minH={'15vh'} direction={{ base: 'column', md: 'row' }} >
      <Flex flex={1}>
    <Box 
    // p={6}
    //         borderWidth="1px"
    //         rounded="lg"
    //         shadow="lg"
    //         ml={2}
    //         maxW={{ base: "100%", sm: "90%", md: "120%" }}
    //         display='flex'
    //         flexDirection="column"
    //          alignItems="center"
    //          justifyContent="center"
    p={10}
            borderWidth="1px"
            rounded="lg"
            shadow="lg"
            ml={2}
            maxWidth='100%'
            display='flex'
            flexDirection="column"
            >
               <Stack align={'center'} m={4} >
              <Heading fontSize={'22px'}
                lineHeight={1.1}
                fontWeight={400}
                marginTop='-4'
                marginBottom='2'
                textColor={'#FF7300'}
              > ZESA Prepaid Electricity
              </Heading>
            </Stack>
      <form onSubmit={handleSubmit}>
        <FormControl mb={4}>
          <FormLabel>Enter Meter Number</FormLabel>
          <Input
            maxWidth='500px'
            type="text"
            value={meterNumber}
            onChange={(e) => setMeterNumber(e.target.value)}
            placeholder="Meter Number"
          />
        </FormControl>
        <Button colorScheme="orange"
         onClick={() => {
          handleFetchData()
          onConOpen()
        }}
        type="submit"
        >
          Request Token
        </Button>
       
      
      </form>

      
     
      {loading ? (
            <Center mt={4}>
              <Spinner size="xl" />
            </Center>
          ):<>
          {error && (
        <Alert status="error" mt={4}>
          <AlertIcon />
          {error}
        </Alert>
      )}

      {/* {data.length > 0 && (
        <TableContainer mt={6}>
          <Table variant="simple">
            <Thead>
              <Tr>
              {data.some((item) => item.token1) && <Th>Token 1</Th>}
              {data.some((item) => item.token2) && <Th>Token 2</Th>}
              {data.some((item) => item.token3) && <Th>Token 3</Th>}
              <Th>Number of Units</Th>
              <Th >Unit Cost</Th>
              <Th >Meter Number</Th>
              <Th >Transcation Date/Time</Th>
              <Th >Total Amount</Th>
              </Tr>
            </Thead>
            <Tbody>
              {data.map((item) => (
                <Tr key={item.id}>
                {item.token1 && <Td>{item.token1}</Td>}
                {item.token2 && <Td>{item.token2}</Td>}
                {item.token3 && <Td>{item.token3}</Td>}
                <Td>{item.numberOfUnits}</Td>
                <Td>{item.unitsCost}</Td>
                <Td>{item.meterNumber}</Td>
                <Td>{item.transactionDate}</Td>
                <Td>{item.totalAmount}</Td>
                
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      )} */}
        

          <Modal
          size={'sm'}
        closeOnOverlayClick={false}
        isOpen={isConOpen}
        onClose={onConClose}
        scrollBehaviour={'outside'}
        >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader bgColor={'blackAlpha.800'} color={'white'}>Zesa Request Token result</ModalHeader>
          <ModalCloseButton />
          {data.map((data) => (
          <ModalBody key ={data.data?.id} >
            <Stack key ={data?.id} mt={2} spacing={1} mb={3}>
            <HStack align={'left'} >

              <Heading as="h2" fontSize="md" color={'#FF7300'}>
                Date:
              </Heading>

              <Text fontSize="md" color={'gray.500'}>
                {/* {meterDetails?.data.name} */}
                {data?.transactionDate}
              </Text>
              </HStack>

              <HStack align={'left'} >

                <Heading as="h2" fontSize="md" color={'#FF7300'}>
                  Meter number:
                </Heading>

                <Text fontSize="md" color={'gray.500'}>
                  {/* {meterDetails?.data.name} */}
                  {data?.meterNumber}
                </Text>
              </HStack>
             

            </Stack>
            <Divider my={2} />
            <Heading as="h1" size="md" textAlign="center" mb={1}>
              Electricity Token
            </Heading>
            {/* <Text textAlign="center" mb={2}>1975 unit A</Text>
    <Text textAlign="center" mb={4}>12/23/20</Text> */}
           
            <Stack spacing={1}>
              <Text textAlign={'center'} fontSize="lg" color={'gray.500'}>
                {data?.data?.token1 !== null ? data?.token1 : ''}
              </Text>
              <Text textAlign={'center'} fontSize="lg" color={'gray.500'}>
                {data?.data?.token2 !== null ? data?.token2 : ''}
              </Text>
              <Text textAlign={'center'} fontSize="lg" color={'gray.500'}>
                {data?.data?.token3 !== null ? data?.token3 : ''}
              </Text>
            </Stack>
            <Divider mt={2} mb={4} />
            <VStack align='left' m={1} spacing={4}>
              <Box>
                <Heading as="h1" fontSize="md" color={'#FF7300'}>
                  Energy bought (kwh):
                </Heading>
                <Text fontSize="md" color={'gray.500'}>
                  {/* {meterDetails?.data.name} */}
                  {data?.numberOfUnits}
                </Text>
              </Box>
             
            
             
    
              {/* <Divider orientation='horizontal' /> */}

            </VStack>
            <Divider my={4} />
            <Flex justify="space-between">
              <Text fontWeight="bold" fontSize={'lg'}>Total Paid</Text>
              <Text fontWeight="bold" fontSize={'lg'}>{data?.transactionCurrency}{data?.totalAmount}</Text>
            </Flex>
            <Box position='relative' padding='6'>
  <Divider />
  <AbsoluteCenter bg='orange' color='white' px='2'>
    End
  </AbsoluteCenter>
</Box>
          </ModalBody>
))}


          <ModalFooter>
            <Button colorScheme='red' mr={3} onClick={onConClose}>
              Close
            </Button>

          </ModalFooter>
        </ModalContent>
      </Modal>
      </>}
    

     

      

      
    </Box>
    </Flex>
    <Flex flex={1} >
          <Box>
            <Image
              objectFit={'contain'}
              height={{ base: '300px', md: '450px' }}
              src={zesa}
            />
          </Box>
        </Flex>
   
</Stack>
</Box>

        



    </>
  );
};

export default RequestToken;
